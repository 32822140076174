import axios from 'axios';
import ServiceSetting from '../settings/ServiceSetting'
import {AccessStorage} from "../storages/AccessStorage";

export class AuthService {
  static login = async (username, password) => {

    let data = new FormData();
    data.set('username', username);
    data.set('password', password);
    data.set('grant_type', 'password');
    data.set('client_id', ServiceSetting.authClientId);

    try {
      let resp = await axios.post(ServiceSetting.authUrl + 'v1/auth/token/event', data);
      AccessStorage.set(resp.data);
      return resp.data;
    } catch (e) {
      return null;
    }

  };

}
