import React from "react";
import ReactDOM from "react-dom";
import {createBrowserHistory} from "history";
import "./assets/css/thomas-event.css";
import {Router, Route, Switch, Redirect} from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import {Login} from "./views/login/Login";
import {AssessmentRequest} from "./views/assessment/AssessmentRequest";

const hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route path="/login" component={Login}/>
      <Route path="/assessment/request/:addonGuid" component={AssessmentRequest}/>
      <Redirect from="/" to="/login"/>
    </Switch>
  </Router>,
  document.getElementById("root")
);
