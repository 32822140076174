import React from "react";
import ThomasLogo from "../assets/img/thomas_logo_no_bg.svg";
import ClientLogo from "../assets/img/petz_logo.png";

export class Header extends React.Component {
  render() {
    return (<div style={styles.header}>
        <a href="https://www.thomasinternational.net/pt-br/"
           target={'_blank'}
           style={styles.container}>
          <div style={styles.imgContainer}>
            <img src={ThomasLogo}
                 alt="Thomas"
                 style={styles.img}/>
          </div>
          <div style={styles.spacer}></div>
          <div style={styles.imgContainer}>
            <img src={ClientLogo}
                 alt="Petz"
                 style={styles.img}/>
          </div>
        </a>
      </div>
    );
  }
}

export const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    maxWidth: '400px',
    margin: 'auto'
  },
  header: {
    paddingTop: '25px',
    paddingBottom: '25px'
  },
  imgContainer: {
    display: 'flex',
    flex: 1,
  },
  img: {
    width: '100%'
  },
  spacer: {
    width: '25px'
  }
};
