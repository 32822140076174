import axios from 'axios';
import ServiceSetting from '../settings/ServiceSetting'
import {AccessStorage} from "../storages/AccessStorage";

export class EventService {
  static get = async () => {
    try {
      let access = AccessStorage.get();
      let resp = await axios.get(
        ServiceSetting.adminUrl + 'v1/event',
        {headers: {"Authorization": `${access['token_type']} ${access['access_token']}`}}
      );
      return resp.data;
    } catch (e) {
      return null;
    }
  };

  static getAddonGuid = async (code, pass) => {
    try {
      let access = AccessStorage.get();
      let resp = await axios.get(
        `${ServiceSetting.adminUrl}v1/event/addon?code=${code}&pass=${pass}`,
        {headers: {"Authorization": `${access['token_type']} ${access['access_token']}`}}
      );
      return resp.data;
    } catch (e) {
      return null;
    }
  };

  static delete = async (eventId) => {
    try {
      let access = AccessStorage.get();
      let resp = await axios.delete(
        `${ServiceSetting.adminUrl}v1/event/${eventId}`,
        {headers: {"Authorization": `${access['token_type']} ${access['access_token']}`}}
      );
      return resp.data;
    } catch (e) {
      return null;
    }
  };

  static post = async (event) => {
    try {
      let access = AccessStorage.get();
      let resp = await axios.post(
        ServiceSetting.adminUrl + 'v1/event', event,
        {headers: {"Authorization": `${access['token_type']} ${access['access_token']}`}}
      );
      return resp.data;
    } catch (e) {
      return null;
    }
  };

  static put = async (event) => {
    try {
      let access = AccessStorage.get();
      let resp = await axios.put(
        ServiceSetting.adminUrl + 'v1/event', event,
        {headers: {"Authorization": `${access['token_type']} ${access['access_token']}`}}
      );
      return resp.data;
    } catch (e) {
      return null;
    }
  };

}
