import React, {Component} from "react";

export class Spacer extends Component {
  render() {
    return (
      <div style={{height: "16px"}}/>
    )
  }

}
