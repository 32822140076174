import React from 'react';
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import 'react-toastify/dist/ReactToastify.css';
import {toast, ToastContainer} from "react-toastify";
import {Header} from "../../components/Header";
import {ContainerStyle} from "../../styles/ContainerStyle";
import {Spacer} from "../../components/Spacer";
import {AssessmentService} from "../../services/AssessmentService";
import {Loading} from "../../components/Loading";
import validarCpf from "validar-cpf";

export class AssessmentRequest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      cpf: '',
      addonGuid: '',
      loading: false
    };
  };

  componentDidMount() {
    const {addonGuid} = this.props.match.params;
    this.setState({addonGuid: addonGuid});
  }

  handleFirstNameChange = async (event) => {
    this.setState({firstName: event.target.value});

  };

  handleLastNameChange = async (event) => {
    this.setState({lastName: event.target.value});
  };

  handleEmailChange = async (event) => {
    this.setState({email: event.target.value});
  };

  handleCpfChange = async (event) => {
    this.setState({cpf: event.target.value.replace(/[^0-9]/, '')});
  };

  render() {
    return (
      <div style={ContainerStyle.container}>
        <Header/>
        <div>
          <Grid container
                spacing={3}>
            <Grid item
                  xs={0}
                  sm={3}/>
            <Grid item
                  xs={12}
                  sm={6}>
              <form>
                <h2>Colaborador/Candidato</h2>
                <TextField className={"AssessmentRequest-TextField"}
                           id="firstName"
                           label="Nome"
                           margin="normal"
                           variant="outlined"
                           fullWidth
                           value={this.state.firstName}
                           onChange={this.handleFirstNameChange}/>
                <TextField className={"AssessmentRequest-TextField"}
                           id="lastName"
                           label="Sobrenome"
                           margin="normal"
                           variant="outlined"
                           fullWidth
                           value={this.state.lastName}
                           onChange={this.handleLastNameChange}/>
                <TextField className={"AssessmentRequest-TextField"}
                           id="email"
                           label="E-mail"
                           margin="normal"
                           variant="outlined"
                           fullWidth
                           value={this.state.email}
                           onChange={this.handleEmailChange}/>
                <TextField className={"AssessmentRequest-TextField"}
                           id="cpf"
                           label="CPF"
                           margin="normal"
                           variant="outlined"
                           fullWidth
                           value={this.state.cpf}
                           onChange={this.handleCpfChange}/>
                <Spacer/>
                <Button
                  size={"large"}
                  fullWidth
                  type="button"
                  color="inherit"
                  variant="outlined"
                  onClick={this.onClickRequestAssessment}>
                  Iniciar avaliação
                </Button>
              </form>
            </Grid>
            <Grid item
                  xs={0}
                  sm={3}/>
          </Grid>
        </div>
        <ToastContainer/>
        <Loading hide={this.state.loading}/>
      </div>
    );
  }

  validateFirstName = (value) => {
    let isValid = value.length > 0;
    if (!isValid) this.toastError('Nome inválido');
    return isValid;
  };

  validateLastName = (value) => {
    let isValid = value.length > 0;
    if (!isValid) this.toastError('Sobrenome inválido');
    return isValid;
  };

  validateEmail = (value) => {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let isValid = re.test(String(value).toLowerCase());
    if (!isValid) this.toastError('Email inválido');
    return isValid;
  };

  validateCpf = (value) => {
    let isValid = validarCpf(value);
    if (!isValid) this.toastError('CPF inválido');
    return isValid;
  };

  validateForm = (firstName, lastName, email, cpf) => {
    return this.validateFirstName(firstName) &
      this.validateLastName(lastName) &
      this.validateEmail(email) &
      this.validateCpf(cpf);
  };

  onClickRequestAssessment = async () => {
    this.setState({loading: true});
    if (this.validateForm(this.state.firstName, this.state.lastName, this.state.email, this.state.cpf)) {
      try {
        let assessment = await AssessmentService.requestAsessment(
          this.state.addonGuid,
          this.state.firstName,
          this.state.lastName,
          this.state.email,
          this.state.cpf);
        window.location.href = assessment['candidateInvitationUrl'];
      } catch (e) {
        this.toastError('Erro ao processar solicitação, tente novamente mais tarde');
      }
    }
    this.setState({loading: false});
  };

  toastError = (msg) => {
    toast.error(msg, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true
    });
  };

}


