export class AccessStorage {
  static set(data) {
    localStorage.setItem('access', JSON.stringify(data));
  }

  static get() {
    let data = localStorage.getItem('access');
    return JSON.parse(data);
  }

  static clear() {
    localStorage.removeItem('access');
  }
}
